<template>
  <div class="content">
    <moreHeader :iconColor='1'></moreHeader>
    <h1>{{data.title}}</h1>
    <div v-if="data.data" class="inAndOut">
      <div v-for="(item , i) in data.data" :key="i">
        <h2>{{item.title}}</h2>
        <p> {{item.text}}</p>
        <img :src="item.img" alt="">
      </div>
    </div>
    <p>{{data.text}}</p>
    <img :src="data.img" alt="" >
    <div class="more">更多场景：</div>

    <div v-if="id!=0" class="moreScene" @click="go(0)">可视化大屏</div>
    <div v-if="id!=1" class="moreScene" @click="go(1)">出入分析系统</div>
    <div v-if="id!=2" class="moreScene" @click="go(2)">视频信息管理系统</div>
    <div v-if="id!=3" class="moreScene" @click="go(3)">研判分析</div>
  </div>
</template>

<script>

import moreHeader from '../../../components/header.vue'
export default {
  components: { moreHeader },
  name: '',
  data () {
    return {
      id: null,
      data: {},
      data1: {
        title: '可视化大屏',
        text: '平台可根据街道、社区、物业不同的职能范围进行首页定制，满足不同层次的管理需要，建立集政府监督、预警联动、智能分析、物业管理、便民服务等于一体的智慧社区业务管理平台，打造小事不出网格，大事不出村社的基层治理新样板。',
        img: require('../../../assets/assets/residentialQuartersImg/img8.png')
      },
      data2: {
        title: '出入分析系统',
        data: [
          {
            title: '人行管理',
            text: '通过物联设备，对出入口人员通道控制系统进行管理，使业主、租户刷脸进出小区。',
            img: require('../../../assets/assets/residentialQuartersImg/img9.png')
          },
          {
            title: '车行管理',
            text: '通过车牌识别摄像头一体机对进出车辆进行控制，分析进入车辆身份。结合地磁感应有效管理车位信息。',
            img: require('../../../assets/assets/residentialQuartersImg/img10.png')
          }
        ]
      },
      data3: {
        title: '视频信息管理系统',
        text: '采用实景指挥系统，通过小区/社区高点图像，统筹全局，联动细节。',
        img: require('../../../assets/assets/residentialQuartersImg/img11.png')
      },
      data4: {
        title: '研判分析',
        text: '以人、事、物、地址、组织等为要素，全面汇集整合小区各类信息资源，使用数据分析和挖掘技术，建立相应的实战应用模型，通过数据碰撞，挖掘出能够提示犯罪运行和变化趋势方向的情报信息，提高小区的安全度。',
        img: require('../../../assets/assets/residentialQuartersImg/img12.png')
      }
    }
  },
  created () {
    this.id = this.$route.query.id
    const i = this.$route.query.id
    // eslint-disable-next-line eqeqeq
    if (i == 0) {
      this.data = this.data1
    }
    // eslint-disable-next-line eqeqeq
    if (i == 1) {
      this.data = this.data2
    }
    // eslint-disable-next-line eqeqeq
    if (i == 2) {
      this.data = this.data3
    }
    // eslint-disable-next-line eqeqeq
    if (i == 3) {
      this.data = this.data4
    }
  },
  methods: {
    go (id) {
console.log(id)

this.$router.push({
        path: '/m_residentialQuartersDetail',
        query: {
          id: id
        }
      })
      this.id=id
      // location.reload()
      if (id == 0) {
        this.id=id
      this.data = this.data1
    }
    // eslint-disable-next-line eqeqeq
    if (id == 1) {
   
      this.data = this.data2
    }
    // eslint-disable-next-line eqeqeq
    if (id == 2) {
      this.data = this.data3
    }
    // eslint-disable-next-line eqeqeq
    if (id == 3) {
      this.data = this.data4
    }
    }
  }
}
</script>

<style scoped lang="less">
.content{
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -27px;
  box-sizing: border-box;
  padding-bottom: 10px;
  >h1{
    padding-left: 10px;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    padding-top: 70px;
  }
  .inAndOut{
    h2{
      padding-left: 10px;
      font-size: 16px;
      font-weight: normal;
      color: #333333;
      margin-top: 20px;
    }
    img{
      padding-left: 10px;
      display: inline-block;
      width: calc(100% - 20px ) ;
    }
  }
  P{
    padding: 0 10px;
    text-indent: 2em;
    // width: 710px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 28px;
    color: #666666;
    margin: 10px 0px;
  }
  >img{
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 20px ) ;
    margin-bottom: 20px;
  }
  .more{
    margin:0 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
    border-top: 1px solid #D5D5D5;
    padding-top: 20px;
  }
  .moreScene{
    padding-left: 20px;
    width: 240px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #365EF1;
    margin-top: 10px;
  }
}
</style>
