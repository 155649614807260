<template>
  <div class="content">
    <div class="logo"></div>
    <img :src="url" alt="" class="more" @click="showPopup" />
    <!-- <div class="more" @click="showPopup"></div> -->
    <van-popup
      v-model="show"
      position="top"
      closeable
      :style="{ height: '100%' }"
    >
      <div class="logo popLogo"></div>
      <div class="selectList">
        <div class="list" @click="go(0)">主页</div>
        <van-collapse v-model="activeNames">
          <van-collapse-item title="产品与服务" name="1">
            <div class="list" @click="go(1)">智慧小区</div>

            <div class="list" @click="go(3)">智慧楼宇</div>
            <div class="list" @click="go(4)">智慧园区</div>
            <div class="list" @click="go(99)">智慧交通</div>
            <div class="list" @click="go(6)">数字乡村</div>
            <div class="list" @click="go(5)">智慧警务</div>
            <div class="list" @click="go(2)">智慧城市</div>

            <div class="list" @click="go(7)">政务大数据</div>
            <!-- <div class="list" @click="go(8)">基层治理</div> -->
            <div class="list" @click="go(9)">智慧水务</div>
          </van-collapse-item>
        </van-collapse>
        <div
          class="list"
          @click="go(88)"
          style="border-bottom: 0.5px solid #ebedf0"
        >
          关于我们
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  name: "",
  props: {
    iconColor: {
      typeof: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      activeNames: ["1"],
      url: require("../../src/assets/assets/more.png"),
      url1: require("../../src/assets/assets/more1.png"),
    };
  },
  created() {
    // eslint-disable-next-line eqeqeq
    if (this.iconColor == "1") {
      this.url = this.url1;
    }
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    go(item) {
      this.show = false;
      // eslint-disable-next-line eqeqeq
      if (item == 0) {
        this.$router.push("/m_homeindex");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 1) {
        this.$router.push("/m_residentialQuarters");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 2) {
        this.$router.push("/m_city");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 3) {
        this.$router.push("/m_building");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 4) {
        this.$router.push("/m_park");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 5) {
        this.$router.push("/m_fbi");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 6) {
        this.$router.push("/m_rural");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 7) {
        this.$router.push("/m_government");
      }
      // eslint-disable-next-line eqeqeq
      if (item == 9) {
        this.$router.push("/m_waterAffairs");
        // window.open('http://www.saixueba.com/')
      }
      if (item == 99) {
        this.$router.push("/m_traffic");
      }
      if (item == 88) {
        this.$router.push("/m_home");
      }
      // eslint-disable-next-line eqeqeq
      // if (item == 8) {
      //   this.$router.push("/m_basic");
      // }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
  z-index: 999;
  top: 50px;
  height: 27px;
  width: 100%;
  // color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  // background-color: #fff;
  .logo {
    width: 60px;
    height: 27px;
    background: url("../../src/assets/assets/logo2.png") no-repeat;
    background-size: contain;
  }
  .more {
    width: 27px;
    height: 27px;
  }
  .popLogo {
    height: 27px;
    margin: 15px 0px 20px 10px;
  }
  .selectList {
    margin: 0 10px;
  }
  .list {
    padding: 12px 16px;
    font-size: 14px;
    color: #333;
  }
}
::v-deep .van-collapse-item__content {
  padding: 0 15px;
}
::v-deep .van-cell::after {
  right: -1px;
  left: -1px;
  border-bottom: 1px solid #ebedf0;
}
</style>
